<template>
  <div class="auth-page">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 d-none d-md-block">
          <AuthLeft :paragraph="para" :button3="btn" :hideButton="hideButton" />
        </div>
        <div class="col-12 col-md-6">
          <div class="auth-page-right  d-flex flex-column justify-content-center">
            <!-- <div class="logo">
              <h4>Global NDA</h4>
            </div> -->

            <div class="auth-page-right-content">
              <div class="heading">
                <h6>Verify Email!</h6>
                <p v-if="errorMessage != null" class="error">
                  {{ errorMessage }}
                </p>
                <p
                  v-if="resendMessage != null"
                  class="tag-line"
                  style="color: green"
                >
                  {{ resendMessage }}
                </p>
                <p v-else-if="resendMessage == null && errorMessage == null">
                  Please Enter the code sent to your Email: {{ userGmail }}
                </p>
              </div>
              <form @keyup.enter="verifyEmail(0)">
                <div class="input-container">
                  <input
                    type="text"
                    v-model="codeOne"
                    class="form-control code"
                    maxlength="1"
                  />
                  <input
                    type="text"
                    v-model="codeTwo"
                    class="form-control code"
                    maxlength="1"
                  />
                  <input
                    type="text"
                    v-model="codeThree"
                    class="form-control code"
                    maxlength="1"
                  />
                  <input
                    type="text"
                    v-model="codeFour"
                    class="form-control code"
                    maxlength="1"
                  />
                </div>
                <button
                  type="button"
                  class="auth-btn"
                  @click="verifyEmail(0)"
                  :disabled="isLoadingArray[0]"
                >
                  <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                  <span v-else>Next</span>
                </button>
                <!-- <div class="account d-md-none">
                  <router-link to="/register" class="forgot-password"
                    >Register</router-link
                  >
                </div> -->
              </form>
              <div v-if="timerCount > 0" class="account">
                <p>You can try again After: {{ timerCount }} second(s).</p>
              </div>
              <div
                v-else-if="timerCount == 0"
                class="account"
                @click="reSend(0)"
              >
                <a href="javascript:void(0);">Resend Code</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AuthLeft from "../../../components/authleft/AuthLeft.vue";

export default {
  name: "VerifyEmail",

  components: {
    AuthLeft,
  },

  data: function() {
    return {
      hideButton: true,
      codeOne: "",
      codeTwo: "",
      codeThree: "",
      codeFour: "",
      errorMessage: null,
      resendMessage: null,
      userGmail: null,
      timerCount: 60,
      isLoadingArray: [],
      details: {
        email: "",
        code: "",
      },
      btn: "login",
      para: "let’s reset your password.",
    };
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  mounted() {
    this.$store.commit("setErrors", {});
    const codes = document.querySelectorAll(".code");

    codes[0].focus();

    codes.forEach((code, idx) => {
      code.addEventListener("keydown", (e) => {
        if (e.key >= 0 && e.key <= 9) {
          codes[idx].value = "";
          setTimeout(() => codes[idx + 1].focus(), 10);
        } else if (e.key === "Backspace") {
          setTimeout(() => codes[idx - 1].focus(), 10);
        }
      });
    });
    this.userGmail = this.getUserGmail();
  },

  methods: {
    ...mapActions("auth", ["sendResetPasswordRequest"]),
    ...mapActions("auth", ["codeVerify"]),

    verifyEmail: function(index) {
      this.details.code =
        this.codeOne + this.codeTwo + this.codeThree + this.codeFour;
      this.$set(this.isLoadingArray, index, true);
      this.details.email = localStorage.getItem("verifyEmail");
      this.codeVerify(this.details).then((response) => {
        if (response.data.statusCode == 200) {
          this.$router.push({ name: "NewPassword" });
        } else {
          setTimeout(() => {
            this.$set(this.isLoadingArray, index, false);
            this.errorMessage = response.data.message;
            setTimeout(() => {
                this.errorMessage = null;
              }, 10000);
            
          }, 500);
        }
      });
    },
    reSend(index) {
      this.resendMessage = null;
      this.errorMessage = null;
      this.timerCount = 0;
      this.$set(this.isLoadingArray, index, true);
      this.details.email = localStorage.getItem("verifyEmail");
      this.sendResetPasswordRequest(this.details).then((response) => {
        if (response.data.statusCode == 200) {
          this.resendMessage = response.data.message;
          setTimeout(() => {
                this.resendMessage = null;
              }, 10000);
          this.$set(this.isLoadingArray, index, false);
          this.timerCount = 60;
        } else {
          setTimeout(() => {
            this.timerCount = 0;
            this.$set(this.isLoadingArray, index, false);
            this.errorMessage = response.data.message;
            setTimeout(() => {
                this.errorMessage = null;
              }, 10000);
          }, 500);
        }
      });
    },
    //method to get user gmail from localStorage
    getUserGmail() {
      const gmail = localStorage.getItem("verifyEmail");
      return gmail;
    }
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
};
</script>
